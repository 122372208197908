<template>
  <div>
    <edit-contract-sidebar />
    <add-receipt-sidebar />
    <query-asaas-identifier-sidebar />
    <request-deletion-sidebar />
    <edit-snapshot-sidebar />
    <div class="d-flex justify-content-between">
      <h1>Informações gerais</h1>
      <b-dropdown
        v-if="!loading && details"
        variant="link"
        no-caret
        right
        boundary="window"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item v-if="canUpdateContract" @click="editContract">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Editar</span>
        </b-dropdown-item>
        <template v-if="canImportPayments">
          <b-dropdown-item @click="addReceipt">
            <feather-icon icon="DollarSignIcon" />
            <span class="align-middle ml-50">Adicionar recebimento</span>
          </b-dropdown-item>

          <b-dropdown-item @click="queryAsaasId">
            <feather-icon icon="SearchIcon" />
            <span class="align-middle ml-50"
              >Consultar identificador Asaas</span
            >
          </b-dropdown-item>

          <b-dropdown-item @click="editSnapshot">
            <feather-icon icon="PieChartIcon" />
            <span class="align-middle ml-50">Editar snapshot</span>
          </b-dropdown-item>
          <!--  -->
          <b-dropdown-item
            v-if="isSubscription || isEP"
            @click="updatePayments"
          >
            <feather-icon icon="RefreshCcwIcon" />
            <span class="align-middle ml-50"
              >Sincronizar pagamentos do contrato</span
            >
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canUpdateContract"
            @click="resetDaysStatus"
          >
            <feather-icon icon="RefreshCcwIcon" />
            <span class="align-middle ml-50">Reiniciar contador de dias</span>
          </b-dropdown-item>
        </template>
        <template v-if="showBtDelete && canDeleteContract">
          <b-dropdown-item @click="requestDeletion">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Excluir</span>
          </b-dropdown-item>
        </template>
        <b-dropdown-item
          v-if="canUpdateImportedPayments"
          @click="reversalPayments"
        >
          <feather-icon icon="RotateCcwIcon" />
          <span class="align-middle ml-50">Estornar comissões</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div v-if="loading || !details" class="d-flex justify-content-center">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="details">
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <div class="d-flex align-items-center mt-2">
          <b-avatar variant="light-primary" rounded>
            <feather-icon icon="AlertCircleIcon" size="18" />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ details.contract_status }}
            </h5>
            <small>Status</small>
          </div>
          <div class="mb-2">
            <b-col cols="auto">
              <b-card-text class="mb-0">
                <b-button
                  id="initial-report-date"
                  variant="flat"
                  class="btn-icon form-horizontal p-0"
                >
                  <feather-icon
                    icon="InfoIcon"
                    size="18"
                    class="text-muted cursor-pointer"
                  />
                </b-button>
                <b-popover
                  target="initial-report-date"
                  triggers="hover"
                  placement="top"
                >
                  {{ details.contract_status_description }}
                </b-popover>
              </b-card-text>
            </b-col>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <b-avatar variant="light-info" rounded>
            <feather-icon icon="DollarSignIcon" size="18" />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ details.gross_value | toCurrency }}
            </h5>
            <small>Valor bruto</small>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <b-avatar variant="light-success" rounded>
            <feather-icon icon="CalendarIcon" size="18" />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ details.monthly_premium | toCurrency }}
            </h5>
            <small>Prêmio mensal</small>
          </div>
        </div>
      </div>
      <b-row class="mt-1">
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Cliente </small>
            <h5 v-if="details.customer">
              <span @click="goToDetails(details.id_customer)" style="cursor:pointer">{{ details.customer }}</span>
            </h5>
            <h5 v-else>Cliente não informado</h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> CPF </small>
            <h5 v-if="details.customer_cpf">
              {{ details.customer_cpf | cpfCnpj }}
            </h5>
            <h5 v-else>CPF não informado</h5>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> E-mail </small>

            <h5 v-if="details.customer_email">
              <a :href="'mailto:' + details.customer_email">
                {{ details.customer_email }}
              </a>
            </h5>
            <h5 v-else>E-mail não informado</h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Celular </small>
            <h5 v-if="details.customer_cell_phone">
              {{ details.customer_cell_phone | formatPhone }}
              <b-button
                class="btn-icon"
                variant="flat"
                style="padding: 0; vertical-align: bottom"
                @click.prevent="goToWhatsapp(details.customer_cell_phone)"
              >
                <feather-icon
                  icon="MessageCircleIcon"
                  size="16"
                  class="text-body"
                  stroke="green"
                />
              </b-button>
            </h5>
            <h5 v-else>Celular não informado</h5>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> PN dono do contrato </small>
            <h5>
              {{ details.consultant }}
            </h5>
          </div>
        </b-col>
        <template v-if="details.closer_consultant">
          <b-col md="6" sm="12" class="mt-1">
            <div>
              <small class="text-muted"> PN closer </small>
              <h5>
                {{ details.closer_consultant || "Closer não informado" }}
              </h5>
            </div>
          </b-col>
        </template>
        <template v-if="details.specialist_consultant">
          <b-col md="6" sm="12" class="mt-1">
            <div>
              <small class="text-muted"> PN especialista </small>
              <h5>
                {{ details.specialist_consultant || "Especialista não informado" }}
              </h5>
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Data do contrato </small>
            <h5>
              {{ details.contract_date | onlyDate }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Solução financeira </small>
            <h5>
              {{ details.financial_solution }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Produto </small>
            <h5>
              {{ details.product }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Empresa </small>
            <h5>
              {{ details.partner_company }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Número da proposta </small>
            <h5>
              {{
                details.proposal_number || "Número da proposta não informado"
              }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Quantidade de dias no status </small>
            <h5>
              {{ details.status_days_count }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <template
          v-if="details.custom_fields && details.custom_fields.length > 0"
        >
          <b-col
            v-for="(field, index) in details.custom_fields"
            :key="field.field_name"
            :md="field.value_type === 'text' ? '12' : '6'"
            sm="12"
            class="mt-1 d-flex"
          >
            <template v-if="!customEditField || 
              customEditField.custom_field_id !== field.id"
            >
              <div>
                <small class="text-muted">
                  {{ field.field_name }}
                </small>
                <h5>
                  <template v-if="field.value_type === 'money'">
                    {{ field.money_value | toCurrency }}
                  </template>
                  <template v-else-if="field.value_type === 'percentage'">
                    {{ field.percentage_value | toPercentage }}
                  </template>
                  <template v-else-if="field.value_type === 'boolean'">
                    {{ field.boolean_value ? "Sim" : "Não" }}
                  </template>
                  <template v-else-if="field.value_type === 'text'">
                    {{ field.text_value || "Não informado" }}
                  </template>
                  <template v-else-if="field.value_type === 'json'">
                    {{ field.json_value || "Não informado" }}
                  </template>
                  <template v-else-if="field.value_type === 'list'">
                    {{ field.list_value || "Não informado" }}
                  </template>
                  <template v-else>
                    {{ field.string_value || "Não informado" }}
                  </template>
                </h5>
              </div>
              <feather-icon
                v-if="field.is_editable && canUpdateContract"
                icon="Edit3Icon" 
                style="cursor: pointer; margin-left: 4px;"
                @click="editCustomField(field)"
              />
            </template>
            <template v-else-if="customEditField && 
              field.is_editable && 
              canUpdateContract
            ">
              <contract-custom-field
                v-model="customEditField.value"
                :financial-solution-field="field"
                :financial-solution-field-index="index"
                :validation="{}"
                :validate="false"
              />
              <b-button
                class="btn-icon ml-1"
                variant="flat"
                style="padding: 0;"
                @click="saveCustomField(field)"
              >
                <feather-icon
                  icon="SaveIcon" 
                />
              </b-button>
              <b-button
                class="btn-icon ml-1"
                variant="flat"
                style="padding: 0;"
                @click="customEditField = undefined"
              >
                <feather-icon
                  icon="XIcon" 
                />
              </b-button>
            </template>
          </b-col>
        </template>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BSpinner,
  BButton,
  BPopover,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import * as types from "@/modules/contract/store/types";
import { useToast } from "vue-toastification/composition";
import { onlyDate, floatToMoney } from "@/helpers/converters";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import EditContractSidebar from "../components/EditContractSidebar";
import AddReceiptSidebar from "../components/AddReceiptSidebar";
import QueryAsaasIdentifierSidebar from "../components/QueryAsaasIdentifierSidebar";
import EditSnapshotSidebar from "../components/EditSnapshotSidebar";
import RequestDeletionSidebar from "../components/RequestDeletionSidebar";
import ContractCustomField from "./ContractCustomField";
import { CREATE_ACTION, UPDATE_ACTION, CONTRACT_RESOURCE, PAYMENT_IMPORT } from "@/constants/resources";
import * as accountTypes from "@/modules/account/store/types";
import * as contractStatus from "@/constants/contract_status";
import * as financial from "@/constants/financial";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BButton,
    BPopover,
    BCardText,
    BDropdown,
    BDropdownItem,
    EditContractSidebar,
    AddReceiptSidebar,
    QueryAsaasIdentifierSidebar,
    EditSnapshotSidebar,
    RequestDeletionSidebar,
    ContractCustomField
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      customEditField: undefined
    };
  },
  computed: {
    ...mapGetters({
      details: types.DETAILS_GENERAL_INFORMATION,
      user: accountTypes.USER,
    }),
    canUpdateContract: function () {
      return this.$can(UPDATE_ACTION, CONTRACT_RESOURCE);
    },    
    canDeleteContract: function () {
      return this.$can(UPDATE_ACTION, CONTRACT_RESOURCE);
    },
    canImportPayments: function () {
      return this.$can(CREATE_ACTION, PAYMENT_IMPORT);
    },
    canUpdateImportedPayments: function () {
      return this.$can(UPDATE_ACTION, PAYMENT_IMPORT);
    },
    isEP() {
      return this.details?.financial_solution_id == financial.EP;
    },
    isSubscription() {
      return (
        this.details?.financial_solution_id == financial.ASSINATURA_SUPORTE ||
        this.details?.financial_solution_id ==
          financial.ACOMPANHAMENTO_BASICO ||
        this.details?.financial_solution_id ==
          financial.ACOMPANHAMENTO_ESSENCIAL ||
        this.details?.financial_solution_id == financial.ACOMPANHAMENTO_ELITE || 
        this.details?.financial_solution_id == financial.GESTAO_FINANCEIRA
      );
    },
    showBtDelete() {
      return (
        this.details?.id_contract_status != contractStatus.DELETED &&
        this.details?.id_contract_status != contractStatus.WAITING_DELETION
      );
    },
  },
  mounted() {
    this.getConsultants()
      .catch(() => {
          this.toast({
          component: ToastificationContent,
          props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
          },
          });
      })
  },
  methods: {
    ...mapActions({
      openEditContract: types.OPEN_EDIT_CONTRACT_SIDEBAR,
      openAddReceiptSidebar: types.OPEN_ADD_RECEIPT_SIDEBAR,
      openQueryAsaasIdSidebar: types.OPEN_QUERY_ASAAS_ID_SIDEBAR,
      openRequestDeletionSidebar: types.OPEN_REQUEST_DELETION_SIDEBAR,
      openEditSnapshot: types.OPEN_EDIT_SNAPSHOT_SIDEBAR,
      updateContractPayments: types.UPDATE_PAYMENTS,
      resetDaysOnStatus: types.RESET_DAYS_ON_STATUS,
      reversalContractPayments: types.REVERSAL_CONTRACT_PAYMENTS,
      getConsultants: types.GET_CONSULTANTS,
      openQueryAsaasIdSidebar: types.OPEN_QUERY_ASAAS_ID_SIDEBAR,
      saveContractCustomField: types.SAVE_CONTRACT_CUSTOM_FIELD,
      getDetailsGeneralInformation: types.GET_DETAILS_GENERAL_INFORMATION
    }),
    get() {
      this.loading = true;
      this.getDetailsGeneralInformation(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os dados do contrato. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToWhatsapp(phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + phone, "_blank").focus();
      });
    },
    editContract() {
      const {
        financial_solution_id,
        proposal_number,
        id_contract_status,
        createdAt,
        signed_at,
        deployed_at,
        paid_at,
      } = this.details;
      this.openEditContract({
        id: this.$route.params.id,
        financial_solution_id,
        proposal_number,
        id_contract_status,
        createdAt: this.getDateString(createdAt),
        signed_at: this.getDateString(signed_at),
        deployed_at: this.getDateString(deployed_at),
        paid_at: this.getDateString(paid_at),
        saveAction: this.get,
      });
    },
    getDateString(date) {
      return onlyDate(date);
    },
    addReceipt() {
      var gross_value = this.isEP ? this.details.gross_value : 0;
      this.openAddReceiptSidebar({
        id: this.$route.params.id,
        saveAction: this.get,
      });
    },
    queryAsaasId() {
      this.openQueryAsaasIdSidebar();
    },
    editSnapshot() {
      this.openEditSnapshot({
        id: this.$route.params.id,
        consultant_id: this.details.consultant.id,
      });
    },
    updatePayments() {
      this.updateContractPayments(this.$route.params.id)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "O contrato foi sincronizado com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.get();
        })
        .catch((err) => {
          if (err.response.status == 405) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text:
                  "Não é possivel sincronizar o contrato. " +
                  err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao sincronizar o contrato. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
      });
    },
    resetDaysStatus() {
      this.resetDaysOnStatus({
        id: this.$route.params.id,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Os dias foram reiciados com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao reiniciar os dias. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.get();
        });
    },
    requestDeletion() {
      this.openRequestDeletionSidebar({
        id: this.$route.params.id,
        saveAction: this.get,
      });
    },
    reversalPayments() {
      this.$swal({
        title: "Tem certeza?",
        text: `Deseja estornar todas as comissões do contrato? A operação não poderá ser desfeita.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.reversalContractPayments({
            id: this.$route.params.id,
          })
            .then((response) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: "Todas as comissões do contrato foram estornadas.",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao estornar as comissões. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.search(this.currentPage);
            });
        }
      });
    },
    goToDetails(customerId) {
      var routeData = this.$router.resolve({
          name: "customer-profile",
          params: { id: customerId },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    editCustomField(field) {
      this.customEditField = {
        custom_field_id: field.id,
        value: field.string_value || 
          field.percentage_value || 
          field.money_value || 
          (field.boolean_value == 1) || 
          field.text_value || 
          field.json_value || 
          field.list_value
      }
    },
    saveCustomField() {
      this.saveContractCustomField({
        id: this.$route.params.id,
        ...this.customEditField
    })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "O campo foi salvo com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.get()
          this.customEditField = undefined
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar o campo. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
    }
  },
};
</script>
